
import 'ol/ol.css';
import { Overlay, Map, View, Marker } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { LineString, Polygon, LinearRing } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import Polyline from 'ol/format/Polyline';
import { fromExtent } from 'ol/geom/Polygon';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import Popup from 'ol/geom/Point';
import Size from 'ol/size';
import * as d3 from "d3";
import { sliderHorizontal } from 'd3-simple-slider';
const opti_icon = require("./data/opti.svg");
const octagon_icon = require("./data/octagon.svg");
const arrow_icon = require("./data/arrow.svg");
const arrow2_icon = require("./data/arrow2.svg");
const cross_icon = require("./data/cross.svg");
import DragFeature from 'ol/Feature';
import Color from 'ol/color';
import { extend, createEmpty, getSize, containsExtent, isEmpty, getArea } from 'ol/extent';
import { FullScreen, defaults as defaultControls } from 'ol/control';
import {
    Select, Translate, Draw, Modify, Snap,
    defaults as defaultInteractions,
} from 'ol/interaction';
import { getLength } from 'ol/sphere';
import Geolocation from 'ol/Geolocation';
import NoSleep from 'nosleep.js';

var randomColor = require('randomcolor'); // import the script

var race = getUrlParameter('race');
var title = race.match(/([A-Z]+[a-z]*|[0-9]+|[a-z][0-9]+|[a-z0-9]*)/g).join(" ");
//title = title.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
console.log("race=", race)
var is_ais = race.toLocaleLowerCase().startsWith("ais");
var is_iq = race.toLocaleLowerCase().startsWith("iq");
var is_malta = race.toLocaleLowerCase().startsWith("round") || race.toLocaleLowerCase().startsWith("thefoiling");

var startDate = new Date() / 1000 + 1 * 60 * 60;
var warnDate = null;
var finishDate = null;
var endDate = startDate + 1 * 60 * 60

var live_mode = null;
var fleet_mode = false;
var top_mode = false;
var dist_mode = false;
var boat_mode = false;
var label_mode = 2; // nothing, rotating sail, sail below, sail + knots, sail + rssi
var boat_to_follow = '';
var last_data_t = 0;
var course_mode = false;
var play_mode = null;    // 0=off otherwise replay speed
var play_interval = 1 / 24; // seconds per second replay speed
var orig_live_delay = 30;     // delay live a bit to get smooth interpolation
if (is_iq || is_malta) {
    orig_live_delay = 60;
}
var live_delay = orig_live_delay;
d3.select("#live-delay-toggle").text(live_delay);
var t;  // global time
var factor = 1;
var time_mode = 0;
var histo_wind_angle = null;
//const PROJECTION = 'EPSG:4326';
const PROJECTION = 'EPSG:3857';
d3.select("#myinfo").text(title)
window.d3 = d3
var live_mode = null;
var bias = null;
var freeze = [];

var select = new Select({
    hitTolerance: 15,
    filter: function (feature, layer) {
        // cannot select LineStrings of boats
        return !(feature.getGeometry() instanceof LineString && track.hasOwnProperty(feature.get("text")));
    },

});

const myslider = sliderHorizontal()
    .width(300)
    .min(- 1 * 60 * 60)
    .max(endDate - startDate)
    .tickFormat(t2s)
    .fill(false)
    .tickValues(d3.range(-5 * 60, 60 * 60, 5 * 60))
    .tickPadding(1);

const gslider = d3.select("#slider")
    .append('svg')
    .attr('width', "100%")
    .attr('height', 100)
    .append('g')
    .attr('transform', 'translate(30,30)');

gslider.call(myslider);

var base_scale = 1.2,
    trail_length = 1;

if (race.startsWith("WinterCoastal")) {
    trail_length = 5;
}
if (race.toLowerCase().startsWith("ais")) {
    trail_length = 6 * 60;
}


var sum = function (x) { return x.reduce((a, b) => a + b, 0); }

var translate = new Translate({
    features: select.getFeatures(),
    //    hitTolerance: 5,
});

var attributions =
    '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy;Openstreetmap</a> contributors.';

var update_distances = function () {
    vectorLayer.getSource().forEachFeature((distance) => {
        if (distance.get("marks")) {
            distance.getGeometry().setCoordinates(distance.get("marks").map(d => mean_coords(d.getGeometry())));
            var d = Math.round(getLength(distance.getGeometry(), { projection: PROJECTION }));
            if (distance.get("popup")) {
                var popup = distance.get("popup");
                popup.get("element").innerHTML = "" + d + "m";
                popup.setPosition(mean_coords(distance.getGeometry()));
            }
        }
    })
}

translate.on('translateend', function (e) {
    window.e = e;
    var name = e.features.getArray()[0].get("name");
    var coord = toLonLat(e.coordinate);
    var cmd = "m";
    if (boat.hasOwnProperty(name)) cmd = "b";
    document.getElementById('command').value = cmd + " " + name + "=" + JSON.stringify(coord);
    update_distances();
    // select.getFeatures().clear();

});

//var fullscreenTarget = document.getElementById('map').parentElement;
var fullscreen = new FullScreen({
    //source: fullscreenTarget
});

var raster = new TileLayer({
    source: new OSM({
        attributions: attributions
    }),
})

var vectorLayer = new VectorLayer({
    updateWhileInteracting: true,
    updateWhileAnimating: true,
    source: new VectorSource({
        features: []
    }),
});

window.slider = slider
var current_zoom = 15;
const map = new Map({
    controls: defaultControls().extend([fullscreen]),
    interactions: defaultInteractions().extend([select]),
    target: 'map',
    layers: [raster, vectorLayer],
    view: new View({
        projection: PROJECTION,
        center: is_malta ? fromLonLat([14.434, 35.897]) : [0, 0],
        zoom: current_zoom - is_ais * 6 - is_malta * 3
    })
});

/*const  draw = new Draw({
    source: vectorLayer.getSource(),
    type: "Polygon"
});

map.addInteraction(draw);
*/

var r_current_state = {};

// map.on("keydown", e => { console.log(t,e); });

var geolocation = new Geolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
        enableHighAccuracy: true,
    },
    projection: map.getView().getProjection(),
});

var map_rotation = 0;
map.getView().on('change:rotation', (d) => {
    map_rotation = (720 - map.getView().getRotation() * 360 / 2 / Math.PI) % 360;
    svg_wind.attr('transform', "translate(-30,-10) rotate(" + (histo_wind_angle + 45 - map_rotation) + " 0 0)");
});

var accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function () {
    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

var positionFeature = new Feature();
positionFeature.setStyle(
    new Style({
        image: new Circle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2,
            }),
        }),
    })
);

geolocation.on('change:position', function () {
    var coordinates = geolocation.getPosition();
    //coordinates[0] += (Math.random()*2-1) * 0.001;
    //coordinates[1] += (Math.random()*2-1) * 0.001;
    if (ws != null) {
        ws.send(JSON.stringify(coordinates));
    }
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
});

vectorLayer.getSource().addFeature(accuracyFeature);
vectorLayer.getSource().addFeature(positionFeature);

var overall_scale;
function recalc_overall_scale() {
    overall_scale = base_scale * 2 ** ((current_zoom - 18) / 6);
}
recalc_overall_scale()

var wind_style = new Style({
    image: new Icon({
        crossOrigin: 'anonymous',
        color: [113, 140, 0],
        src: arrow_icon,
        scale: 0.5 * overall_scale,
        rotateWithView: true,
        anchor: [0.5, 4.6],
        rotation: -3.14 / 2,
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
    })
});

var cross_style = new Style({
    image: new Icon({
        crossOrigin: 'anonymous',
        color: [113, 140, 0],
        src: cross_icon,
        scale: 0.05 * overall_scale * 10,
        anchor: [0.5, 0.5],   // 0.7 0.5
        rotateWithView: true,
        rotation: 0,
    })
});


function hashCode(s) { // java String#hashCode
    /* Simple hash function. */
    var a = 1, c = 0, h, o;
    if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = (a << 6 & 268435455) + o + (o << 14);
            c = a & 266338304;
            a = c !== 0 ? a ^ c >> 21 : a;
        }
    }
    return String(a);
}

function intToRGB(i) {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}


var opti_style = new Style({
    image: new Icon({
        crossOrigin: 'anonymous',
        color: 'rgba(50,110,140)',
        opacity: 0.4,
        src: opti_icon,
        scale: 0.8 * overall_scale,
        rotateWithView: true,
        anchor: [0.5, 60],
        rotation: -3.14 / 2,
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
    })
});

var safety_style = new Style({
});



var mean_coords = function (geom) {
    var coords = geom.getCoordinates();
    if (geom.getType() == "LineString") {
        for (var i = 1; i < coords.length; i++) {
            coords[0][0] += coords[i][0];
            coords[0][1] += coords[i][1];
        }
        coords[0][0] /= coords.length;
        coords[0][1] /= coords.length;
        coords = coords[0];
    }
    return coords;
}

var line_style = new Style({
    stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.25)',
        lineDash: [5, 5],
    }),
})

var distance = null;

var add_distance = function (c0, c1) {
    distance = new Feature({
        geometry: new LineString([]),
        text: 'distance'
    });
    distance.setStyle(line_style);
    var coords1 = mean_coords(marks[c0].getGeometry());
    var coords2 = mean_coords(marks[c1].getGeometry());
    distance.getGeometry().appendCoordinate(coords1)
    distance.getGeometry().appendCoordinate(coords2)
    vectorLayer.getSource().addFeature(distance)
    distance.set("marks", [marks[c0], marks[c1]])

    var d = Math.round(getLength(distance.getGeometry(), { projection: PROJECTION }));
    var element = document.createElement('div');
    element.className = 'ol-tooltip ol-tooltip-measure';
    element.innerHTML = "" + d + "m";
    var popup = new Overlay({
        element: element,
        offset: [0, 8],
        positioning: 'bottom-center',
    });
    popup.setPosition(mean_coords(distance.getGeometry()));
    distance.set("popup", popup);
    map.addOverlay(popup);
}

var create_distances = function () {
    var all_distances = {};
    for (var i = 0, prev_raw_name = ""; i < r_course.length; i++) {
        var raw_name = r_course[i].replace(/\/.*/, ""); // strip after "/"
        if (raw_name.indexOf(":") > 0) {
            var [m0, m1] = raw_name.split(":");
            add_distance(m0, m1);
            //add_line(raw_name, mdict_for_marks[m0], mdict_for_marks[m1], 0.5);
        }
        if (i > 0 && !all_distances.hasOwnProperty(prev_raw_name + ";" + raw_name)) {
            if (raw_name.indexOf(":") > 0) {
                var [m0, m1] = raw_name.split(":");
                if (prev_raw_name.indexOf(":") > 0) {
                    var [p0, p1] = prev_raw_name.split(":");
                    add_distance(p0, m0);
                    add_distance(p1, m1);
                } else {
                    add_distance(prev_raw_name, m0);
                    add_distance(prev_raw_name, m1);
                }
            } else {
                if (prev_raw_name.indexOf(":") > 0) {
                    var [p0, p1] = prev_raw_name.split(":");
                    add_distance(p0, raw_name);
                    add_distance(p1, raw_name);
                } else {
                    add_distance(prev_raw_name, raw_name);
                }
            }
            all_distances[prev_raw_name + ";" + raw_name] = 1;
            all_distances[raw_name + ";" + prev_raw_name] = 1;
        }
        prev_raw_name = raw_name;
    }
}

var delete_distances = function () {

    vectorLayer.getSource().forEachFeature((distance) => {
        if (distance.get("marks")) {
            if (distance.get("popup")) {
                map.removeOverlay(distance.get("popup"));
            }
            vectorLayer.getSource().removeFeature(distance);
        }
    })
}


select.on('select', (e) => {
    console.log("select", e);
    var textarea = document.getElementById('command');
    textarea.value = (new Date()).toLocaleString() + "\n" + e + "\n";
    textarea.scrollTop = textarea.scrollHeight;

    if (e.selected.length == 1 && e.deselected.length == 0) {
        if (e.selected[0].get("text") == "distance") {
            // delete the thing if clicked
            distance = e.selected[0];
            if (distance.get("popup")) {
                map.removeOverlay(distance.get("popup"));
            }
            vectorLayer.getSource().removeFeature(distance);
            select.getFeatures().clear();
            return;
        }
        distance = new Feature({
            geometry: new LineString([]),
            text: 'distance'
        });
        distance.setStyle(line_style);
        var coords = mean_coords(e.selected[0].getGeometry());
        distance.getGeometry().appendCoordinate(coords)
        vectorLayer.getSource().addFeature(distance)
        distance.set("marks", [e.selected[0]])
        var bb = e.selected[0].get("text")

        if (boat_mode) {
            if (boat.hasOwnProperty(bb)) {
                select.getFeatures().clear();
                if (boat.hasOwnProperty(boat_to_follow)) {
                    boat[boat_to_follow].getStyle().getStroke().setWidth(1)
                    track[boat_to_follow].getStyle().getImage().setScale(0.09 * overall_scale)
                    track[boat_to_follow].getStyle().getImage().setOpacity(0.6)

                }
                if (boat_to_follow != bb) {
                    boat_to_follow = bb;
                    boat[boat_to_follow].getStyle().getStroke().setWidth(4)
                    track[boat_to_follow].getStyle().getImage().setScale(0.11 * overall_scale)
                    track[boat_to_follow].getStyle().getImage().setOpacity(1)
                    d3.select("#boat-button").text(boat_to_follow);
                } else {
                    boat_to_follow = "";
                    d3.select("#boat-button").text("click boat");
                }
            }
        }

    } else if (e.selected.length == 1 && e.deselected.length == 1) {

        distance.getGeometry().appendCoordinate(mean_coords(e.selected[0].getGeometry()));
        distance.get("marks").push(e.selected[0]);

    } else if (e.selected.length == 0 && e.deselected.length == 1) {

        var d = Math.round(getLength(distance.getGeometry(), { projection: PROJECTION }));
        if (d == 0) {
            vectorLayer.getSource().removeFeature(distance);
        } else {
            var element = document.createElement('div');
            element.className = 'ol-tooltip ol-tooltip-measure';
            element.innerHTML = "" + d + "m";
            var popup = new Overlay({
                element: element,
                offset: [0, 8],
                positioning: 'bottom-center',
            });
            popup.setPosition(mean_coords(distance.getGeometry()));
            distance.set("popup", popup);
            map.addOverlay(popup);
        }
    }
})

let style = new Style({
    image: new Circle({
        radius: 6,
        stroke: new Stroke({
            color: '#000',
            width: 2,
        })
    }),
});

function binarySearch(ar, el, compare_fn) {
    var m = 0;
    var n = ar.length - 1;
    while (m <= n) {
        var k = (n + m) >> 1;
        var cmp = compare_fn(el, ar[k]);
        if (cmp > 0) {
            m = k + 1;
        } else if (cmp < 0) {
            n = k - 1;
        } else {
            return k;
        }
    }
    return m; //  -m - 1;
}


var slider,
    handle,
    boat;
document.d3 = d3;

window.myslider = myslider;
var width = window.innerWidth - 150,
    height = 70;

// use https://stackoverflow.com/questions/40261382/moving-the-handle-of-a-d3-js-slider
var svg = d3.select("#slider2")
    .append("svg")
    .attr("style", "position: absolute;")
    .attr("width", width)
    .attr("height", height);

var update_xaxis = function () {
    width = window.innerWidth - 150;
    svg.attr("width", width);
    x.range([50, width - 50]);
    gX.call(xAxis);
    myslider.width(width - 80);
    myslider.tickFormat(t2s)
    let xxx = (myslider.max() - myslider.min()) / 10;
    const interval = Math.round(xxx / 300) * 300;
    const mymin = -Math.floor((- myslider.min()) / interval) * interval;
    const mymax = Math.floor((myslider.max()) / interval) * interval;

    myslider.tickValues(d3.range(mymin, mymax, interval))
    gslider.call(myslider);
};


current_zoom = map.getView().getZoom();
var save_user_zoom = false, user_zoom = current_zoom;

var rescale_objects = function (e) {
    var zoom = map.getView().getZoom();
    if (zoom != current_zoom || e === true) {
        current_zoom = zoom;
        recalc_overall_scale();
        for (var b in track) {
            track[b].octagon_style.getImage().setScale(0.1 * overall_scale);
            track[b].opti_style.getImage().setScale(0.1 * overall_scale);
            track[b].getStyle().getImage().setScale(0.1 * overall_scale);
            track[b].getStyle().getText().setScale(0.8 * overall_scale);
        }
        for (var b in marks) {
            marks[b].getStyle().getImage().setScale(0.8 * overall_scale);
            marks[b].getStyle().getText().setScale(1.2 * overall_scale);
        }
    }
    save_user_zoom = true;
    rescale_timer = null;
}

map.getView().on('moveend', (e) => {
    rescale_objects();
});


map.getView().on('change:resolution', function (e) {
    if (save_user_zoom) {
        user_zoom = map.getView().getZoom();
        rescale_objects();
    }
});

map.on("change:size", e => {
    svg.attr("width", 10);
    setTimeout(update_xaxis, 200);
})

var xTicks = {
    "0": "Start",
    "29": "Finish",
};

var formatMinute = d3.format("+.0f");
var currentValue;

var x = d3.scaleLinear()
    .range([50, width - 50])
    .clamp(true);

x.domain([-60 * 60, 60 * 60]);
var ticks = d3.range(-60 * 60, 60 * 60, 15 * 60)
ticks.push(2900);


var xAxis = d3.axisBottom().scale(x)
    //.tickValues(ticks)
    .tickFormat(function (t) {
        return xTicks[t] || formatMinute(t / 60) + " min.";
    })
    .tickSize(12, 3)
    .tickSizeOuter(0)
    .tickPadding(5);

var gX = svg.append("g")
    .attr("class", "g-x g-axis")
    .attr("transform", "translate(0," + 30 + ")")
    .call(xAxis);


var mouse_button = 0;
svg.append("rect")
    .style("pointer-events", "all")
    .style("fill", "none")
    .attr("width", '100%')
    .attr("height", '100%')
    .style("cursor", "crosshair")
    .on("mousedown", function () {
        mouse_button = 1;
        updatePos(this);
    }).on("mouseenter", function () {

    }).on("mouseleave", function () {
        console.log(d3.event);

    }).on("mouseup", function () {
        mouse_button = 0;
        console.log(this, t - startDate)
        if (t - startDate <= -3600 || t - startDate >= 3600) {
            if (t - startDate <= -3600) {
                ws.send(JSON.stringify({ msg: 'setup', race: race, t0: startDate - 3600 }))
                toggle_live(0);
            } else if (t - startDate >= 3600) {
                ws.send(JSON.stringify({ msg: 'setup', race: race, t0: startDate + 3600 }))
            }
            for (var b in mpos) {
                //vectorLayer.getSource().removeFeature(marks[b])
                delete marks[b];
                delete mpos[b];
            }
            for (var b in boat) {
                //vectorLayer.getSource().removeFeature(boat[b])
                //vectorLayer.getSource().removeFeature(track[b])
                delete boat[b];
                delete track[b];
                delete data[b]
            }
            vectorLayer.getSource().clear();
        }

    })
    .on("touchstart", function () {
        mouse_button = 1;
        updatePos(this);
    })
    .on("touchend", function () {
        mouse_button = 0;
        updatePos(this);
    })

    .on("mousemove", function () {
        if (d3.event.buttons == 1 && mouse_button == 1) {
            updatePos(this);
        }
    }).on("touchmove", function () {
        if (mouse_button == 1) {
            updatePos(this);
        }
    });
;
const nhour = 24;
myslider.on("onchange", function (t_seconds) {
    t = startDate + t_seconds;
    updatePosT(t);
}).on("drag", function (t_seconds) {
    //t = startDate + t_seconds;
    //updatePosT(t);
}).on("start", function (t_seconds) {
    t = startDate + t_seconds;
    updatePosT(t);
    mouse_button = 1;
    myslider.value(t_seconds);
}).on("end", function (t_seconds) {
    mouse_button = 0;
    console.log("end - here check for bounds ", t_seconds)
    let flag = false;
    if (t_seconds == myslider.min()) {
        var old_warnDate = warnDate;
        if (is_ais) {
            warnDate = Math.floor(warnDate / 300) * 300 - nhour * 3600
            console.log("ais ok", warnDate)
        } else {
            warnDate -= 0  // maybe subtract 5 minutes to see general recall ??
        }
        ws.send(JSON.stringify({ msg: 'setup', race: race, 't0': warnDate - nhour * 3600, 't1': old_warnDate }))
        toggle_live(0);
        console.log("min hit");
        flag = true;
    } else if (t_seconds == myslider.max()) {
        ws.send(JSON.stringify({ msg: 'setup', race: race, t0: Math.floor(startDate / 300) * 300 + nhour * 3600 }))
        flag = true;
    }
    if (flag) {
        for (var b in mpos) {
            vectorLayer.getSource().removeFeature(marks[b])
            delete marks[b];
            delete mpos[b];
        }
        for (var b in boat) {
            vectorLayer.getSource().removeFeature(boat[b])
            vectorLayer.getSource().removeFeature(track[b])
            delete boat[b];
            delete track[b];
            delete data[b]
        }
        vectorLayer.getSource().clear();
    }



})







//map.on("pointerup", d => {mouse_button=0;})
//map.on("pointermove", d => {})



function time_to_str(t_seconds, tm) {
    var tstr;
    if (tm == undefined) tm = time_mode;
    if (tm == 0) {
        var min = Math.floor(Math.abs(t_seconds) / 60);
        var sec = Math.floor(Math.abs(t_seconds) % 60);
        var sign = "";
        if (t_seconds < 0) {
            sign = "-";
        }
        tstr = sign + Math.abs(min) + "m" + sec + "s"
    } else {
        tstr = new Date((startDate + t_seconds) * 1000).toTimeString().substr(0, 8)
    }
    return tstr;
}

function t2s(t_seconds) {
    return time_to_str(t_seconds)
}

function updatePos(elem) {
    var xPos = d3.mouse(elem)[0];
    handle.attr('transform', 'translate(' + xPos + ",0)");
    var t_seconds = x.invert(xPos);
    t = startDate + t_seconds;
    text.text(time_to_str(t_seconds));
    updatePosT(t);
}


function updatePosX(t_) {
    if (t_ == undefined) {
        if (mouse_button) return;
        t = new Date() / 1000 - live_delay;
    } else {
        t = t_;
    }
    if (t > endDate) {
        if (live_mode != null) {
            clearInterval(live_mode);
            d3.select("#live-button").text("live");
            //ws.close();
        }
    }
    var t_seconds = t - startDate;
    var xPos = x(t_seconds);
    handle.attr('transform', 'translate(' + xPos + ",0)");
    text.text(time_to_str(t_seconds));
    myslider.value(t_seconds);
    updatePosT(t);
}

var update_course_time = 0,
    update_ranking_time = 0,
    update_cwind_time = 0;

var angles = [];
function updatePosT(t1) {

    function cmp(a, b) {
        return a - b[0];
    }
    // keep nsec_hist seconds of history
    var nsec_hist = 3;
    angles.splice(0, Math.max(angles.length - (nsec_hist / play_interval - 1) * Object.keys(data).length, 0))
    var myangles = [];
    for (let name in data) {
        var trail = data[name]
        var i1 = binarySearch(trail, t1, cmp)
        if (i1 >= trail.length) {
            i1 = trail.length - 1;
        }
        if (i1 < 0) i1 = 0;
        var i0 = i1, i00 = i1;   // i00 is just for sfreq with trail_length=1min
        while (trail[i0][0] > t1 - trail_length * 60 && i0 > 0) i0 -= 1;
        while (trail[i00][0] > t1 - 1 * 60 && i0 > 0) i00 -= 1;
        var l = boat[name].getGeometry();
        l.setCoordinates(trail.slice(i0, i1 + 1).map(d => [d[1], d[2]]))
        if (track.hasOwnProperty(name)) {
            var x, y, phi, delta;
            if (i1 > 0) {
                delta = (t1 - trail[i1 - 1][0]) / (trail[i1][0] - trail[i1 - 1][0]);
                if (delta > 1) { delta = 1; }
                x = trail[i1 - 1][1] * (1 - delta) + (delta) * trail[i1][1]
                y = trail[i1 - 1][2] * (1 - delta) + (delta) * trail[i1][2]
                var dphi = trail[i1][3] - trail[i1 - 1][3];
                if (dphi > Math.PI) { dphi -= 2 * Math.PI }
                if (dphi < -Math.PI) { dphi += 2 * Math.PI }
                phi = trail[i1 - 1][3] + (delta) * dphi - Math.PI / 2;
            } else {
                x = trail[i1][1]
                y = trail[i1][2]
                phi = trail[i1][3] - Math.PI / 2
            }
            var phi360 = phi / Math.PI * 180 + 90;
            if (1) {
                if (r_current_state.hasOwnProperty(name)
                    && r_current_state[name].next_mark < r_nm
                    && r_pos[name] < Object.keys(r_pos).length  // do not use dsq
                    && i0 < i1 - 1) {
                    var next_mark = r_course[r_current_state[name].next_mark];
                    if (next_mark.indexOf("/") > 0) {
                        //console.log(name, next_mark, phi360)
                        myangles.push(((phi360 + 180 - heading_mid) % 360 - 180 + heading_mid));
                    }
                }
            }
            if (track[name].hasOwnProperty("active_style")) {
                if (i0 >= i1 - 1 && track[name].active_style != 1) {
                    track[name].setStyle(track[name].octagon_style);
                    track[name].active_style = 1
                }
                if (i0 < i1 - 1 && track[name].active_style != 2) {
                    track[name].setStyle(track[name].opti_style);
                    track[name].active_style = 2
                }
                var s = track[name].getStyle();

                if (typeof s == "object") {
                    s.getImage().setRotation(phi);
                    if (label_mode == 1) s.getText().setRotation((phi + Math.PI / 2) % Math.PI - Math.PI / 2);
                    if (label_mode == 3) {
                        s.getText().setText(name + "\n" + trail[i1][4] + " knts");
                    } else if (label_mode == 4) {
                        s.getText().setText(name + "\n" + trail[i1][5]);
                    } else if (label_mode == 5) {
                        s.getText().setText(name + "\n" + Math.round((i1 - i00) / (trail_length)) + "/min");
                    } else if (label_mode == 6) {
                        s.getText().setText(name + " (" + r_pos[name] + ")");
                    }

                    /* s.setText(new Text({
                       text: name,
                       fill: new Fill({
                       color: 'rgba(0, 0, 0)',
                       opacity: 0.2,
                       }),
                       //text: name + "\n" +  trail[i1][4] +" knts" + "\n" +  trail[i1][5],  // speed
                       //text: name + "\n" +  trail[i1][4] +" knts",  // speed
                       //text: name + "\n" +  trail[i1][5] ,  // rssi
                       offsetY: 15,
                       }));
                    */
                }
                track[name].getGeometry().setCoordinates([x, y]);
            }
        }
    }
    // console.log(myangles.length);
    if (myangles.length > 5) {
        angles.push(...myangles);
    }

    if (Math.abs(t1 - update_cwind_time) > 1 * factor) {
        cwind.update(angles);
        update_cwind_time = t1;
    }
    if (Math.abs(t1 - update_course_time) > 0.1 * factor) {
        draw_course(t1);
        update_distances();
        update_course_time = t1;
    }
    if (r_nm > 0 && Math.abs(t1 - update_ranking_time) > 1 * factor) {
        get_ranking(t1);
        update_ranking_time = t1;
    }

}

var handle = svg.append("g")
    .attr("class", "handle")

handle.append("path")
    .attr("transform", "translate(0," + height / 2 + ")")
    .attr("d", "M 0 -15 V 15")

var text = handle.append('text')
    .on("click", () => { time_mode = 1 - time_mode; updatePosX(t); })
    .text(x.domain()[0] / 60)
    .attr("transform", "translate(" + (-18) + " ," + (height / 2 - 20) + ")");

d3.select("#slider").selectAll(".parameter-value text").on("click", () => { time_mode = 1 - time_mode; updatePosX(t); })

fullscreen.on('enterfullscreen', function (event) {
    setTimeout(() => update_xaxis, 1000);
})

var data = {}
var boat = {}
var mpos = {}; // position of marks over time
var track = {}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

String.prototype.title = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

var uri = "wss://" + window.location.host + "/ws/";
if (window.location.host.startsWith("localhost")) uri = "ws://localhost:8080/ws"
console.log(uri);
var ws = null;
var ws_timeout = 250;
var marks_undo = [];
var admin = false;
var wind = {};
var line_list = ["RC:pinend", "f1:f2"];  // draw lines between marks, replaced if course is set

var r_state = {},
    r_prev_state = {}, // remember the min distance to previous mark for forther checks
    r_all_state = {},
    r_time = 0,
    r_i0 = 0,
    r_ufd = [],
    r_ufd_type = {},
    r_course = [],
    r_nm = r_course.length,
    r_dt = 2,   // resolution of ranking computation in seconds
    r_dt_min = null,
    r_dt_max = null;


var toggle_live = function (mode) {
    // switch live_mode on/off or toggle without argument
    if (mode !== undefined) {
        if (live_mode != null && mode == 1) return;
        if (live_mode == null && mode == 0) return;
    } else {
        toggle_play(0); // OFF
    }
    if (live_mode != null) {
        clearInterval(live_mode);
        live_mode = null;
        d3.select("#live-button").text("live");
    } else {
        live_mode = setInterval(updatePosX, 1000 * play_interval)
        d3.select("#live-button").text("live!!");
        console.log("went live")
        if (new Date() / 1000 > endDate && is_ais) {
            ws.send(JSON.stringify({ 'msg': 'setup', 'race': race }));
        }
    }
}

var toggle_play = function (mode) {
    if (mode !== undefined) {
        if (play_mode != null && mode == 1) return;
        if (play_mode == null && mode == 0) return;
    } else {
        toggle_live(0); // OFF
    }
    if (play_mode == null) {
        play_mode = setInterval(function () {
            if (mouse_button) return;
            t += play_interval * factor;
            updatePosX(t)
        }, 1000 * play_interval)
        d3.select("#play-button").text("pause");
    } else {
        clearInterval(play_mode);
        play_mode = null;
        d3.select("#play-button").text("play");
    }
}

var connectWebSocket = function () {
    if (document.hidden) {
        ws = null;
        setTimeout(connectWebSocket, 1000);
        return;
    }
    ws = new WebSocket(uri);

    ws.onopen = function (e) {

        ws_timeout = 250; // reset in successful case
        d3.select("#dot").transition().duration(1000)
            .style("background-color", "green")

        document.cookie.split("; ").forEach(key => {
            if (key.startsWith("login=")) {
                this.send(JSON.stringify({
                    race: race,
                    t: null,
                    msg: key
                }))
                // show admin consoles
                map.getInteractions().extend([translate]);
                d3.select("#command").style("display", "unset")
                // d3.select("#logger").style("display","unset")
                d3.select("#smap").style("display", "unset")
                d3.select("#send-button").style("display", "unset")
                d3.select("#send-button2").style("display", "unset")
                d3.select("#get-button2").style("display", "unset")
                d3.select("#live-delay-toggle").style("display", "unset")
            }
        });

        whatever0();
        this.send(JSON.stringify({ 'msg': 'setup', 'race': race, 't0': last_data_t }));

        d3.select("#live-button").on("click", () => {
            toggle_play(0);
            toggle_live();
        });

        d3.select("#dbtn").on("click", () => {
            factor -= 1;
            d3.select("#ebtn").text("x " + factor);
        });

        d3.select("#fbtn").on("click", () => {
            factor += 1;
            d3.select("#ebtn").text("x " + factor);
        });

        d3.select("#ebtn").on("click", () => {
            factor *= 2;
            if (factor > 64) { factor = 1 }
            d3.select("#ebtn").text("x " + factor);
        });

        d3.select("#play-button").on("click", toggle_play);
    };

    ws.onmessage = function (event) {
        var ll = JSON.parse(event.data);

        if (ll.hasOwnProperty("msg")) {
            if (ll.msg == "smap") {

                document.getElementById('smap').value = ll.data + "\n";
                if (ll.data.indexOf("m fboat=m pinend") >= 0) {
                    document.getElementsByClassName("macro")[0].classList.add("selected")
                }
                if (ll.data.indexOf("m fboat=m f2") >= 0) {
                    document.getElementsByClassName("macro")[1].classList.add("selected")
                }

            } else if (ll.msg == "log") {

                var textarea = document.getElementById('command');
                textarea.value += ll.data + "\n";
                textarea.scrollTop = textarea.scrollHeight;

            } else if (ll.msg == "admin_ok") {

                admin = true;
                document.cookie = "login=" + ll.pass;
                map.getInteractions().extend([translate]);
                d3.select("#command").style("display", "unset")
                d3.select("#smap").style("display", "unset")
                d3.select("#send-button").style("display", "unset")
                d3.select("#send-button2").style("display", "unset")
                d3.select("#get-button2").style("display", "unset")
                d3.select("#live-delay-toggle").style("display", "unset")
                d3.select("#macros")
                    .selectAll("div")
                    .data(["fboat→pin", "fboat→f2", "5min", "sync", "c1", "c2", "copy"])
                    .enter().append("div")
                    .attr("class", "macro")
                    .text(d => d)
                    .on("click", (d, i, e) => {
                        e[i].classList.toggle("selected");
                        console.log(d);
                        if (i == 0) {
                            if (e[i].classList.contains("selected")) {
                                ws.send(JSON.stringify({ msg: 'smap', data: 'm fboat=m pinend', race: race }));
                            } else {
                                ws.send(JSON.stringify({ msg: 'smap', data: 'm fboat=', race: race }));
                            }
                            e[1].classList.remove("selected") // remove selected from other
                        } else if (i == 1) {
                            if (e[i].classList.contains("selected")) {
                                ws.send(JSON.stringify({ msg: 'smap', data: 'm fboat=m f2', race: race }));
                            } else {
                                ws.send(JSON.stringify({ msg: 'smap', data: 'm fboat=', race: race }));
                            }
                            e[0].classList.remove("selected")
                        } else if (i == 2) {
                            if (e[i].classList.contains("selected")) {
                                document.getElementById("command").value = 'start=in 5 min';
                                //				ws.send( JSON.stringify( {msg: 'start=in 5 min', race:race} ) );
                            }
                            setTimeout(() => e[i].classList.remove("selected"), 1000);
                        } else if (i == 3) {
                            document.getElementById("command").value = "start=in " + Math.floor((startDate - new Date() / 1000) / 60) + " min";
                            setTimeout(() => e[i].classList.remove("selected"), 1000);
                        } else if (i == 4) {
                            var a = Math.round(heading_mid);
                            if (a < 0) a += 360;
                            var c = "RC-m1/" + a + "-m2-3s:3p-f1:f2/" + a;
                            //document.getElementById("command").value = c
                            ws.send(JSON.stringify({ msg: 'smap', data: 'course=' + c, race: race }));

                            setTimeout(() => e[i].classList.remove("selected"), 1000);
                        } else if (i == 5) {
                            var a = Math.round(heading_mid);
                            if (a < 0) a += 360;
                            var c = "RC-m1/" + a + "-m2-3s:3p-m2/" + a + "-3s:3p-f1:f2/" + a;
                            //document.getElementById("command").value = c
                            ws.send(JSON.stringify({ msg: 'smap', data: 'course=' + c, race: race }));
                            setTimeout(() => e[i].classList.remove("selected"), 1000);
                        } else if (i == 6) {
                            if (e[i].classList.contains("selected")) {
                                document.getElementById("command").value = 'copy';
                            }
                            setTimeout(() => e[i].classList.remove("selected"), 1000);
                        }
                    })

            } else if (ll.msg == "wind") {

                wind_angle = +ll.values.windDirection;
                wind_speed = +ll.values.windSpeed * 1.94384; // m/s to knots
                console.log("wind_angle=", wind_angle);
                //svg_wind.attr('transform',"rotate(" + (wind_angle + map.getView().getRotation()*360/2/3.14159265 ) + " 0 0)");
                //d3.select("#wind").select("text").text(wind_speed.toFixed(1));

            } else if (ll.msg == "markslayer_ok") {

                d3.select("#marks")
                    .selectAll("div")
                    .data(["m1", "m2", "m3", "m4", "RC", "pinend", "f2", "undo"])
                    .enter().append("div")
                    .attr("class", "mark")
                    .text(d => d)
                    .on("click", d => {
                        if (d == "undo") {
                            if (marks_undo.length > 0) {
                                var msg = marks_undo.splice(-1, 1)[0];
                                var res = confirm("undo " + msg);
                                if (res) {
                                    ws.send(msg);
                                }
                            }
                        } else {
                            var res = confirm("setting " + d + " at " + positionFeature.getGeometry().getCoordinates());
                            if (res) {
                                var msg = {
                                    'msg': "mark " + d + "=" + JSON.stringify(positionFeature.getGeometry().getCoordinates()),
                                    't': new Date() / 1000,
                                    'vart': t,
                                    'race': race
                                };
                                ws.send(JSON.stringify(msg));
                                msg.msg = "mark " + d + "="; // prepare delete message
                                marks_undo.push(JSON.stringify(msg));
                            }
                        }
                    });
                endDate = 1e11;
                /** as admin don't switch on tracking, it consumes battery **/

                if (!admin) {

                    geolocation.setTracking(true);
                    d3.select("#controls").style("display", "none");

                }

            } else if (ll.msg == "upcoming") {

                // just disable controls to avoid confusion
                //                d3.select("#controls").style("display", "unset");
                toggle_live(1);

            } else if (ll.msg == "drop") {

                if (ll.hasOwnProperty("mark") && marks.hasOwnProperty(ll.mark)) {
                    vectorLayer.getSource().removeFeature(marks[ll.mark])
                    delete marks[ll.mark];
                    delete mpos[ll.mark];
                }

            } else if (ll.msg == "delete") {

                if (ll.hasOwnProperty("mark") && marks.hasOwnProperty(ll.mark)) {
                    var arr = mpos[ll.mark];
                    arr.splice(-1, 1);
                    if (mpos[ll.mark].length == 0) {
                        vectorLayer.getSource().removeFeature(marks[ll.mark]);
                        delete marks[ll.mark];
                    } else {
                        marks[ll.mark].getGeometry().setCoordinates([arr[arr.length - 1][1], arr[arr.length - 1][2]]);
                    }
                }

            } else if (["ufd", "bfd", "dsq", "dns"].indexOf(ll.msg) >= 0) {

                r_ufd.push(...ll.data);
                ll.data.forEach(d =>
                    r_ufd_type[d] = ll.msg
                )
                console.log("ufd", r_ufd, r_ufd_type);

            } else if (ll.msg == "sockets") {

                console.log(ll);
                d3.select("#sockets").text(ll.value);

            } else if (ll.msg == "bias") {

                bias = ll.data[0];
                if (bias == "") bias = null;

            } else if (ll.msg == "freeze") {

                freeze = ll.data;

            } else if (ll.msg == "scale") {

                base_scale = +ll.data[0];
                console.log("base_scale", ll, base_scale)
                recalc_overall_scale();
                rescale_objects(true);

            } else if (ll.msg == "course") {

                // receive info about a course so let's activate the ranking list function
                // course instruction is to be received after the initial boat and mark data
                // but only if a race has been started

                if (r_nm != ll.data.length || !ll.data.every((v, k) => v === r_course[k])) {
                    console.log("re-init course", ll.data);
                    r_course = ll.data;
                    r_nm = r_course.length;
                    course_mid = null;
                    line_list = [];
                    for (var i = 0; i < r_nm; i++) {
                        var r = r_course[i].replace(/\/.*/, "");
                        if (r.indexOf(":") > 0) line_list.push(r);
                    }
                    console.log("line_list", line_list);
                    for (var i = 0; i < r_nm; i++) {
                        if (r_course[i].indexOf("/") > 0) {
                            course_mid = +r_course[i].split("/")[1];
                            course_mid = (course_mid + 360 + 90) % 360 - 90
                            console.log("course_mid", course_mid);
                            heading_mid = course_mid;
                            break;
                        }
                    }
                    whatever0();
                    whatever();
                }
                d3.select("#ranking").style("display", "unset");

            } else if (ll.msg == "setup_done") {

                updatePosX(t);
                update_xaxis();

            } else if (ll.msg == "start") {

                console.log("start", race, ll)
                d3.select("#controls").style("display", "unset");
                console.log("start", ll, new Date(ll.startDate * 1000));
                startDate = ll.startDate;
                endDate = ll.endDate;
                if (warnDate === null) warnDate = startDate - 5 * 60;
                if (new Date() / 1000 < endDate) { // live mode
                    if (live_mode == null) {
                        toggle_live(1);
                        d3.select("#live-button").text("live!!");
                    }
                    t = new Date() / 1000;
                    if (is_ais) {
                        console.log("setup ais")
                        base_scale = 3;
                        // do this once trail_length = 5; // minutes
                        time_mode = 1;
                        recalc_overall_scale();
                        startDate = Math.floor(t / 300) * 300;
                        endDate = startDate + 60 * 60;
                        x.domain([-60 * 60 * nhour, endDate - startDate]);
                        gX.call(xAxis);
                        myslider.min(-60 * 60 * nhour).max(endDate - startDate);
                        gslider.call(myslider);
                    } else {

                        x.domain([warnDate - startDate, endDate - startDate]);
                        gX.call(xAxis);
                        myslider.min(Math.min(warnDate - startDate, t - startDate - live_delay)).max(endDate - startDate);
                        update_xaxis();
                        gslider.call(myslider);

                    }
                } else {
                    if (is_ais) {
                        base_scale = 3;
                        recalc_overall_scale();
                        t = startDate;
                        x.domain([-60 * 60 * nhour, endDate - startDate]);
                        myslider.min(-60 * 60 * nhour).max(endDate - startDate);
                        gslider.call(myslider);
                        gX.call(xAxis);
                    } else {
                        // replay mode
                        d3.select("#live-button").style("display", "none");
                        t = warnDate;
                        x.domain([warnDate - startDate, endDate - startDate]);
                        myslider.min(warnDate - startDate).max(endDate - startDate);
                        gslider.call(myslider);
                        gX.call(xAxis);
                    }
                }

            } else if (ll.msg == "end") {

                endDate = ll.endDate;
                x.domain([warnDate - startDate, endDate - startDate]);
                myslider.max(endDate - startDate);
                gX.call(xAxis);

            } else {
                console.log(ll);
            }
            return;
        }
        var l, d;
        for (var i in ll) {
            l = ll[i];
            d = { name: l[0], t: l[1] / 100, lon: l[2] / 1e6, lat: l[3] / 1e6 };
            // vel:(l[4] / 1e3 * 1.9438436).toFixed(2), head:l[5]/1e5, rssi:l[6]}

            var [dlon, dlat] = fromLonLat([d.lon, d.lat], PROJECTION);
            if (d.name == undefined) { console.log(l); return; }

            console.log(l);
            if (l[4] == null && l[5] == null
                || d.name.startsWith("m ")
                || d.name.startsWith("w ")
            ) {  // ============== mark data =================
                var wind = false;
                if (d.name.startsWith("m ") || d.name.startsWith("w ")) {
                    if (d.name.startsWith("w ")) wind = true;
                    d.name = d.name.substr(2);
                    console.log("manual mark", d, wind)
                }
                if (d.name == "pinend" && d.t > startDate + 60) {
                    // ignore pinend after start
                    // continue;
                }
                if (!marks.hasOwnProperty(d.name)) {
                    console.log("new mark", d.name, wind);
                    var s;
                    if (wind) {
                        if (d.name == "cross") {
                            s = cross_style.clone();
                            console.log("cross found");
                        } else
                            s = wind_style.clone();
                    } else {
                        s = mark_style.clone();
                    }
                    marks[d.name] = new Feature({
                        geometry: new Point(fromLonLat([d.lon, d.lat])),
                        style: s,
                        wind: wind,
                        name: d.name
                    });
                    vectorLayer.getSource().addFeature(marks[d.name]);
                    marks[d.name].setStyle(s);
                    marks[d.name].getStyle().setText(new Text({
                        text: d.name,
                        offsetY: d.name == "cross" ? -15 : 15,
                        scale: 0.8 * overall_scale
                    }));
                    mpos[d.name] = [];
                }


                if (Object.keys(mpos).length == 1) {
                    if (map.getView().getCenter().every((d) => d == 0)) {
                        map.getView().setCenter(fromLonLat([d.lon, d.lat]));
                        console.log("set center")
                    }
                }
                // mpos[d.name] = [] // for now just reset so we update only
                var arr = mpos[d.name];
                if (arr.length == 0 || d.t > arr[arr.length - 1][0]) {
                    // console.log("append")
                    arr.push([d.t, dlon, dlat, (l[4] / 1e3 * 1.9438436).toFixed(1), l[5] / 1e5]);
                } else {
                    var i1 = binarySearch(arr, d.t, (a, b) => a - b[0]);
                    // console.log("check this",d.t, arr[i1][0]);
                    if (d.t != arr[i1][0]) arr.splice(i1, 0, [d.t, dlon, dlat, (l[4] / 1e3 * 1.9438436).toFixed(1), l[5] / 1e5]);
                }
                if (d.name == "cross") {
                    cspeed.update(arr)
                    cangle.update(arr)
                    console.log("l=", l, l[5] / 1e5)
                }

                last_data_t = d.t;
                // draw_course(t);
                // console.log(t);
                // update_distances();

            } else {
                // ========================= boat data ==========================

                if (!data.hasOwnProperty(d.name)) {
                    console.log("new boat", d.name);
                    data[d.name] = []
                    track[d.name] = new Feature({
                        geometry: new Point(fromLonLat([d.lon, d.lat])),
                        name: d.name,
                        text: d.name
                    });
                    track[d.name].opti_style = new Style({
                        image: new Icon({
                            crossOrigin: 'anonymous',
                            color: randomColor({ seed: hashCode(d.name) }),
                            opacity: 0.6,
                            src: opti_icon,
                            scale: 0.1 * overall_scale,
                            rotateWithView: true,
                            anchor: [0.5, 60],
                            rotation: -3.14 / 2,
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                        })
                    });
                    track[d.name].octagon_style = new Style({
                        image: new Icon({
                            crossOrigin: 'anonymous',
                            color: randomColor({ seed: hashCode(d.name) }),
                            opacity: 0.6,
                            src: octagon_icon,
                            scale: 0.1 * overall_scale,
                            rotateWithView: true,
                            anchor: [0.5, 60],
                            rotation: -3.14 / 2,
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                        })
                    });

                    track[d.name].setStyle(track[d.name].opti_style);
                    track[d.name].active_style = 2;

                    //track[d.name].getStyle().setColor(randomColor({ seed: hashCode(d.name) }));

                    // console.log(d.name ,  randomColor({seed: hashCode(d.name)}));


                    track[d.name].getStyle().setText(new Text({
                        // text: d.name.replace(/([A-Z]{3})(\d+)/,"$1\n$2" ) ,
                        text: d.name.replace(/ - /, "\n"),
                        offsetY: 12,
                        fill: new Fill({
                            opacity: 0.8,
                        }),
                        //rotateWithView: true,
                        scale: overall_scale * 0.8,
                        weight: "bold",
                    }));
                    track[d.name].opti_style.setText(track[d.name].getStyle().getText())
                    track[d.name].octagon_style.setText(track[d.name].getStyle().getText())

                    boat[d.name] = new Feature({
                        geometry: new LineString([]),
                        text: d.name
                    });

                    boat[d.name].setStyle(new Style({
                        stroke: new Stroke({
                            color: randomColor({ seed: hashCode(d.name) }), //'rgba(0, 50, 200, 0.4)',
                            width: 1
                        })

                    }));

                    vectorLayer.getSource().addFeature(boat[d.name])
                    vectorLayer.getSource().addFeature(track[d.name])
                    if (Object.keys(track).length == 1) {
                        setTimeout(function () {
                            fleet_mode = true;
                            course_mode = true;
                            fleet_mode_fn("force");
                            console.log("initial fleet mode")
                            course_mode = false;
                            fleet_mode = false;
                        }, 10)
                        if (map.getView().getCenter().every((d) => d == 0)) {
                            map.getView().setCenter(fromLonLat([d.lon, d.lat]));
                            console.log("set center")
                        }
                    }
                }

                var arr = data[d.name];
                console.log(d.name, new Date(d.t * 1000))
                if (arr.length == 0 || d.t > arr[arr.length - 1][0]) {
                    arr.push([d.t, dlon, dlat, l[5] / 1e5 / 360 * 2 * Math.PI, (l[4] / 1e3 * 1.9438436).toFixed(1), l[6]]);
                } else {
                    var i1 = binarySearch(arr, d.t, (a, b) => a - b[0]);
                    if (d.t != arr[i1][0]) arr.splice(i1, 0, [d.t, dlon, dlat, (l[4] / 1e3 * 1.9438436).toFixed(1), l[5] / 1e5]);
                }
                last_data_t = d.t;
            }
        }
        // updatePosX(t);
    };

    ws.onclose = function (event) {
        d3.select("#dot").transition().duration(1000)
            .style("background-color", "yellow");
        if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log(`[close] Connection died code=${event.code}`);
            if (endDate > new Date() / 1000) {
                ws = null;
                setTimeout(connectWebSocket, Math.min(10000, ws_timeout += ws_timeout));
            }
        }
    };

    ws.onerror = function (error) {
        d3.select("#dot").transition().duration(1000)
            .style("background-color", "red")
        console.log(`[error] ${error.message}`);
        ws.close();
    };
}

ws = null;
connectWebSocket()

var mark_style = new Style({
    image: new Circle({
        radius: 3,
        //	fill: new Fill({color: 'white'}),
        stroke: new Stroke({
            color: 'black', width: 1
        })
    })
});

var marks = {};
var lines = {};

var point_dist_bearing = function (coord, dist, bearing) {
    const radius = 6371008.8;
    const lonlat = toLonLat(coord);
    const lon1 = lonlat[0] / 180 * Math.PI;
    const lat1 = lonlat[1] / 180 * Math.PI;
    bearing = bearing * Math.PI / 180;
    var lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist / radius) +
        Math.cos(lat1) * Math.sin(dist / radius) * Math.cos(bearing));
    var lon2 = lon1 + Math.atan2(Math.sin(bearing) * Math.sin(dist / radius) * Math.cos(lat1),
        Math.cos(dist / radius) - Math.sin(lat1) * Math.sin(lat2));
    return fromLonLat([lon2 * 180 / Math.PI, lat2 / Math.PI * 180]);
}

var add_mark = function (m) {
    if (!marks.hasOwnProperty(m)) {

        marks[m] = new Feature({
            geometry: new Point([0, 0], PROJECTION),
            name: m
        });
        marks[m].setStyle(mark_style.clone());
        marks[m].getStyle().setText(new Text({
            text: "perp",
            offsetY: 15,
            scale: 0.8 * overall_scale
        }));
        vectorLayer.getSource().addFeature(marks[m]);
    }
}

var draw_course = function (t1) {
    if (t1 == undefined) {
        t1 = new Date() / 1000 - live_delay;
    }

    function cmp(a, b) {
        return a - b[0];
    }
    // mpos instead of marks since opin does not have tracks in mpos
    for (let name in mpos) {
        var wind = marks[name].get("wind");
        var trail = mpos[name]
        var i1 = binarySearch(trail, t1, cmp);
        if (i1 >= trail.length) {
            i1 = trail.length - 1;
        }
        if (i1 < 0) i1 = 0;
        //console.log("found pos", name, t, i1, t1, trail, trail.length);
        var skip = false;
        if (freeze.length > 0 &&
            freeze.indexOf(name) >= 0 &&
            t1 - startDate > 0) skip = true;
        if (!skip) {
            var delta, x, y;
            if (i1 > 0) {
                delta = (t1 - trail[i1 - 1][0]) / (trail[i1][0] - trail[i1 - 1][0]);
                // console.log(t1,delta, trail[i1-1], trail[i1]);
                if (delta > 1) { delta = 1; }
                x = trail[i1 - 1][1] * (1 - delta) + (delta) * trail[i1][1]
                y = trail[i1 - 1][2] * (1 - delta) + (delta) * trail[i1][2]
            } else {
                x = trail[i1][1]
                y = trail[i1][2]
            }

            marks[name].getGeometry().setCoordinates([x, y]);
        }
        if (wind) {
            var phi = (trail[i1][4] + 360) % 360;
            if (course_mid == null) {
                heading_mid = (phi + 360 + 90) % 360 - 90;
            }
            cwind.xmid(heading_mid);
            marks[name].getStyle().getImage().setRotation((phi + 90) / 360 * 2 * Math.PI);
            marks[name].getStyle().setText(new Text({
                text: trail[i1][3] + " knts",
                offsetY: name == "cross" ? -15 : 15,
                offsetX: name == "cross" ? -25 : 0,
            }));
            if (name == "cross") {
                cspeed.updateVertical(t1)
                cangle.updateVertical(t1)
            }
            //svg_wind.attr('transform',"rotate(" + ( phi + 45 + map.getView().getRotation()*360/2/Math.PI ) + " 0 0)");
            //d3.select("#wind").select("text").text(trail[i1][3]);

        }
    }

    if (bias !== null) {
        const opin = "opin"; // optimal pinend
        if (!marks.hasOwnProperty(opin)) {
            marks[opin] = new Feature({
                geometry: new Point([0, 0], PROJECTION),
                name: opin
            });
            marks[opin].setStyle(mark_style.clone());
            marks[opin].getStyle().setText(new Text({
                text: "unbiased",
                offsetY: 15,
                scale: 0.8 * overall_scale
            }));
            vectorLayer.getSource().addFeature(marks[opin]);
        }

        const radius = 6371008.8;
        var startline = [
            toLonLat(marks["RC"].getGeometry().getCoordinates()),
            toLonLat(marks["pinend"].getGeometry().getCoordinates())
        ];
        var angle;
        if (bias == "histo") {
            angle = histo_wind_angle - 90;
        } else {
            angle = marks[bias].getStyle().getImage().getRotation() / Math.PI * 180 - 180;
        }
        var d = getLength(new LineString(startline), { projection: 'EPSG:4326' });
        var bearing = angle / 180 * Math.PI;
        const lon1 = startline[0][0] / 180 * Math.PI;
        const lat1 = startline[0][1] / 180 * Math.PI;
        var lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / radius) +
            Math.cos(lat1) * Math.sin(d / radius) * Math.cos(bearing));
        var lon2 = lon1 + Math.atan2(Math.sin(bearing) * Math.sin(d / radius) * Math.cos(lat1),
            Math.cos(d / radius) - Math.sin(lat1) * Math.sin(lat2));
        marks[opin].getGeometry().setCoordinates(fromLonLat([lon2 * 180 / Math.PI, lat2 * 180 / Math.PI]))
        //console.log([lon2 * 180 / Math.PI, lat2 * 180 / Math.PI]);

        if (line_list.indexOf("RC:" + opin) == -1) {
            line_list.push("RC:" + opin);
        }
    } else {
        const opin = "opin"; // optimal pinend
        if (marks.hasOwnProperty(opin)) {
            vectorLayer.getSource().removeFeature(marks[opin]);
            delete marks[opin];
        }
        if (line_list.indexOf("RC:" + opin) != -1) {
            line_list.splice(line_list.indexOf("RC:" + opin), 1);
            console.log(line_list);
        }

    }

    if (0) {   // lines along wind direction
        var angle;
        if (bias == "histo") {
            angle = histo_wind_angle - 90;
        } else {
            angle = marks[bias].getStyle().getImage().getRotation() / Math.PI * 180 - 180;
        }
        var name = r_first;
        if (r_current_state.hasOwnProperty(name) && r_current_state[name].next_mark < r_nm) {
            var next_mark = r_course[r_current_state[name].next_mark];
            const m0 = "first0";  // perpendicular line for the first
            const m1 = "first1";  // perpendicular line for the first
            if (next_mark.indexOf("/") > 0) {
                add_mark(m0);
                add_mark(m1);
                var d = r_state[r_first].d;    // distance to next mark
                console.log(d);

                marks[m0].getGeometry().setCoordinates(
                    point_dist_bearing(track[name].getGeometry().getCoordinates(),
                        d, angle)
                )

                marks[m1].getGeometry().setCoordinates(
                    point_dist_bearing(track[name].getGeometry().getCoordinates(),
                        d, angle + 180)
                )

                if (line_list.indexOf(m0 + ":" + m1) == -1) {
                    line_list.push(m0 + ":" + m1);
                }

            } else {
                if (line_list.indexOf(m0 + ":" + m1) != -1) {
                    line_list.splice(line_list.indexOf(m0 + ":" + m1), 1);
                }
            }
        }
        console.log(r_first, line_list, d);
    }

    // draw lines between certain marks
    var to_delete = Object.keys(lines);
    line_list.forEach(aabb => {
        var ii = to_delete.indexOf(aabb); // remove aabb form to_delete
        if (ii >= 0) {
            to_delete.splice(ii, 1);
        }
        var m1 = aabb.split(":")[0]
        var m2 = aabb.split(":")[1]
        if (marks.hasOwnProperty(m1) && marks.hasOwnProperty(m2)) {
            if (!lines.hasOwnProperty(aabb)) {
                lines[aabb] = new Feature({
                    geometry: new LineString([]),
                    text: aabb
                });
                if (aabb == "RC:opin")
                    lines[aabb].setStyle(new Style({
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.3)',
                            lineDash: [.5, 5],
                            width: 2
                        })
                    }));

                vectorLayer.getSource().addFeature(lines[aabb]);
            }
            lines[aabb].getGeometry().setCoordinates(
                [marks[m1].getGeometry().getCoordinates(),
                marks[m2].getGeometry().getCoordinates()
                ])
            // console.log("line", lines[aabb].getGeometry().getCoordinates());
        }
    })
    to_delete.forEach(aabb => {
        vectorLayer.getSource().removeFeature(lines[aabb]);
        delete lines[aabb];
    })
    if (fleet_mode || course_mode || boat_mode || top_mode) fleet_mode_fn();
};

// ranking
/*

start to end , with some interval (maybe 1 second, maybe 10)




*/

var getCurrentPos = function (trail, t1) {
    function cmp(a, b) {
        return a - b[0];
    }
    var i1 = binarySearch(trail, t1, cmp)
    if (i1 >= trail.length) {
        i1 = trail.length - 1;
    }
    if (i1 < 0) i1 = 0;
    var i0 = i1;
    while (trail[i0][0] > t1 - 1 * 60 && i0 > 0) i0 -= 1;
    var x, y, phi, delta;
    if (i1 > 0) {
        delta = (t1 - trail[i1 - 1][0]) / (trail[i1][0] - trail[i1 - 1][0]);
        if (delta > 1) { delta = 1; }
        x = trail[i1 - 1][1] * (1 - delta) + (delta) * trail[i1][1]
        y = trail[i1 - 1][2] * (1 - delta) + (delta) * trail[i1][2]
        //var dphi = trail[i1][3] - trail[i1-1][3];
        //if (dphi >  Math.PI ) {dphi -= 2*Math.PI}
        //if (dphi < -Math.PI ) {dphi += 2*Math.PI}
        //phi = trail[i1-1][3] + (delta) * dphi - Math.PI/2;
    } else {
        x = trail[i1][1]
        y = trail[i1][2]
        //phi = trail[i1][3] - Math.PI/2
    }
    return toLonLat([x, y]);
}


/*
var lat2 = Math.asin( Math.sin(lat1)*Math.cos(distance/radius) +
                    Math.cos(lat1)*Math.sin(distance/radius)*Math.cos(bearing) );
var lon2 = lon1 + Math.atan2(Math.sin(bearing)*Math.sin(distance/radius)*Math.cos(lat1),
                         Math.cos(distance/radius)-Math.sin(lat1)*Math.sin(lat2));
*/


var perpDistance = function (pos, pos1, angle) {
    // angle is the wind direction where wind comes from
    var l1 = getLength(new LineString([pos, pos1]), { projection: "EPSG:4326" });
    var bearing = (angle + 90) / 180 * Math.PI;
    var distance = 1000,
        radius = 6371008.8;
    var lon1 = pos1[0] / 180 * Math.PI,
        lat1 = pos1[1] / 180 * Math.PI;
    var lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / radius) +
        Math.cos(lat1) * Math.sin(distance / radius) * Math.cos(bearing));
    var lon2 = lon1 + Math.atan2(Math.sin(bearing) * Math.sin(distance / radius) * Math.cos(lat1),
        Math.cos(distance / radius) - Math.sin(lat1) * Math.sin(lat2));
    var pos2 = [lon2 / Math.PI * 180, lat2 / Math.PI * 180];
    var lring = new LinearRing([pos, pos1, pos2]);
    var area = Math.abs(lring.getArea() * 1e10);
    var b = getLength(new LineString([pos1, pos2]), { projection: "EPSG:4326" });
    var d = 2 * area / b; // b should be 100 meters.
    //console.log(l1, d, area, b, b1,b2,lring.getArea());
    return d;
}

var getDistance2 = function (pos1, pos2, angle) {
    var f = Math.sqrt(2);
    var d = getLength(new LineString([pos1, pos2]), { projection: "EPSG:4326" });
    if (angle != null) {
        var d2 = perpDistance(pos1, pos2, angle) * f;
        if (d2 > d) d = d2;
    }
    return d;
}

var getDistance = function (pos, next_mark, t1, boat) {
    var d, dperp;
    var angle, m, mm = r_course[next_mark];
    var pos1, pos2, area;
    mm = mm.split("/");
    if (mm.length == 1) {
        m = mm[0];
        angle = null;
    } else {
        m = mm[0];
        angle = +mm[1];
    }
    if (m.indexOf(":") >= 0) {  // distance to a line
        var ddd = m.split(":");
        pos1 = getCurrentPos(mpos[ddd[0]], t1);
        pos2 = getCurrentPos(mpos[ddd[1]], t1);
        var lring = new LinearRing([pos, pos1, pos2]);
        var d12 = getLength(new LineString([pos1, pos2]), { projection: "EPSG:4326" })
        dperp = 2 * lring.getArea() * 1e10 / d12;
        var l1 = getDistance2(pos, pos1, null);
        var l2 = getDistance2(pos, pos2, null);
        if (l1 > d12 || l2 > d12) {
            // pos is not between pos1 and pos2,
            // crosses the line but outside of the 2 marks
            dperp = 0;    // invalidate dperp
        }
        l1 = getDistance2(pos, pos1, angle);
        l2 = getDistance2(pos, pos2, angle);
        var lmm = 11111;
        var mid, lmid, b = null;
        while (lmm > 1) {
            mid = [(pos1[0] + pos2[0]) / 2, (pos1[1] + pos2[1]) / 2];
            lmid = getDistance2(pos, mid, angle);
            if (l1 < l2) {
                l2 = lmid;
                pos2 = mid;
            } else {
                l1 = lmid;
                pos1 = mid;
            }
            lmm = getLength(new LineString([pos1, pos2]), { projection: "EPSG:4326" });
        }
        d = lmid;

    } else { // distance to another point

        pos1 = getCurrentPos(mpos[m], t1);
        d = getDistance2(pos, pos1, angle);
        dperp = 0;
    }
    return [d, dperp];
}

var tr_old;
var bdebug = null; //"602"; null; // "19";
var update_ranks = function (tr, itr) {
    for (var b in data) { // loop over boat names
        if (!r_state.hasOwnProperty(b)) {
            r_state[b] = {
                next_mark: 1,  // if next_mark == r_nm then boat has finished
                d: 0,          // distance
                dperp: 0,      // perpendicular distance when crossing line
            };
            r_prev_state[b] = { last_mark: 1, dd: [] };
        }
        var o = r_state[b];
        var p = r_prev_state[b];
        if (o.next_mark < r_nm || p.last_mark < r_nm) {  // not yet finished
            var pos = getCurrentPos(data[b], tr);
            var d, dperp, d2;
            if (o.next_mark < r_nm) {
                [d, dperp] = getDistance(pos, o.next_mark, tr, b);
                o.d = d;
                o.dperp = dperp;
                if (b == bdebug) { console.log(time_to_str(tr - startDate), b, dperp, d, o, p); }
                if (r_all_state.hasOwnProperty(itr - 2)) {
                    var crossed = Math.sign(dperp) * Math.sign(r_all_state[itr - 1][b].dperp) == -1;
                    if (r_all_state[itr - 1][b].d < 100.0
                        && r_all_state[itr - 1][b].d < d     // d will become r_all_state[itr][b].d
                        && r_all_state[itr - 1][b].d <= r_all_state[itr - 2][b].d
                        || crossed) {
                        // reached checkpoint
                        o.next_mark += 1;
                        if (o.next_mark == r_nm) { // done
                            // maybe extrapolate with current speed
                            /*
                              var vperp = (r_all_state[itr-2][b].d - r_all_state[itr-1][b].d) / r_dt
                              var tperp = r_all_state[itr-1][b].d / vperp
                              if ( b==bdebug ) {console.log("tperp=", tperp);}
                            */
                            o.d = tr - startDate - r_dt; // + tperp // at finish keep total time in o.l2
                        } else {
                            [d2, dperp] = getDistance(pos, o.next_mark, tr, b);
                            o.d = d2;
                        }
                        p.last_mark = o.next_mark - 1;
                        p.d = d;
                        // if dperp changes sign we know the boat has crossed
                        // the line between the marks (remember if outside the mark dperp==0)
                        // so set min distance to zero, we do not recheck for smaller
                        // min distance
                        if (crossed) {
                            p.d = 0;
                            o.d = (tr - startDate) - r_dt * (Math.abs(dperp)
                                / (Math.abs(dperp) + Math.abs(r_all_state[itr - 1][b].dperp)))
                            console.log("boat", b, "crossed");
                        }
                        // if ( b=="1707" ) {console.log("cp",time_to_str(tr-startDate),b,d,o,p);}
                    }
                }
            }
            // re-check for smaller distance the the last mark
            // in case boat move to mark, then away, then again closer.
            // we want the absolute minimum distance to the mark
            if (true && o.next_mark > 1 && p.last_mark == o.next_mark - 1) {
                [d, dperp] = getDistance(pos, o.next_mark - 1, tr, b);
                if (b == bdebug) { console.log(time_to_str(tr - startDate), b, dperp, d, o, p); }
                if (d < 60) {
                    p.dd.push(d);
                    if (d < p.d && p.d > 1) { // closer than ever before
                        // console.log(itr, b, p.l1, p.dd)
                        // so we revert back to previous mark
                        var otmp, itr_prev;
                        for (var i = 1; i < p.dd.length; i++) {
                            itr_prev = itr - p.dd.length + i;
                            otmp = r_all_state[itr - p.dd.length + i][b];
                            otmp.d = p.dd[i - 1] //  r_all_state[itr_prev-1][b].d;
                            otmp.next_mark = p.last_mark;
                        }
                        // fix o for current state (itr is not yet in r_all_state, doh)
                        o.d = d;
                        o.next_mark = p.last_mark;
                        if (b == bdebug) for (var i = 0; i < p.dd.length + 3; i++) {
                            console.log(time_to_str((itr - p.dd.length - 3 + i) * r_dt - startDate), b, r_all_state[itr - p.dd.length - 3 + i][b]);
                        }
                        // console.log("ll", time_to_str(itr*r_dt-startDate), b, o, r_state[b]);
                        p.d = d;
                        p.last_mark = p.last_mark;
                        p.dd.splice(0, p.dd.length);
                        // console.log(b, p);
                    }
                } else {
                    p.last_mark = o.next_mark;  // far enough away, really go to next mark
                    p.dd.splice(0, p.dd.length);
                    p.d = 1e11;
                }
            }
        }
    }
}

// initialize, put that into some function later
var whatever0 = function () {
    for (var b in data) { // loop over boat names
        var o = {
            next_mark: 1,  // if next_mark == r_nm then boad is finished
            d: 0,          // distance
            dperp: 0,      // perpendicular distance when crossing line
        }
        r_state[b] = o;
        r_prev_state[b] = { last_mark: 1, dd: [] };
    }
    clist.update([]);
    clist.nrows(Object.keys(data).length);
    r_i0 = 0;
    r_all_state = {};
}

var whatever_ii = 0;
var whatever = function () {
    var tr = new Date() / 1000 - orig_live_delay;
    if (tr > endDate) {
        tr = endDate;
        if (whatever_ii != 0) {
            clearInterval(whatever_ii);
        }
        whatever_ii = 1; // do not setInterval (again)
    }
    if (tr < startDate) {
        whatever0();
        console.log("race not yet started", r_nm, r_i0)
    } else {
        if (r_i0 == 0) r_i0 = Math.round(startDate / r_dt);
        var i1 = Math.round(tr / r_dt);
        for (var i = r_i0; i <= i1; i++) {
            // console.log(r_i0, i1, tr-t);
            update_ranks(i * r_dt, i);
            r_all_state[i] = JSON.parse(JSON.stringify(r_state));
        }
        r_i0 = i1 + 1;
    }
    if (whatever_ii == 0) {
        whatever_ii = setInterval(whatever, 1000);
    }
}

var r_list = [];
var r_pos = {};
var r_first = null;
var get_ranking = function (tr) {
    var r_state;
    if (tr == undefined) tr = t;
    if (tr < startDate) tr = startDate;
    if (tr > endDate) tr = endDate;
    var ii = Math.round(tr / r_dt);
    var rf = function (a, b) {
        //console.log(r_state,a,b);
        if (r_state[a]['next_mark'] == r_state[b]['next_mark']) {
            return r_state[a]['d'] - r_state[b]['d']
        } else {
            return r_state[b]['next_mark'] - r_state[a]['next_mark']
        }
    }
    if (r_all_state.hasOwnProperty(ii)) {
        r_state = r_all_state[ii];
        var key_list = Object.keys(r_state);
        r_list = key_list.sort(rf);
        var race_finished_ = true;

        for (var j = 1, i = 0; i < r_list.length; i++) {
            var d = r_list[i];
            if (r_state[d].next_mark != r_nm) race_finished_ = false;
            if (r_ufd.indexOf(d) >= 0) {
                r_pos[d] = r_ufd_type[d];
            } else {
                if (j == 1) r_first = d;
                r_pos[d] = "" + j++;
            }
        }
        if (race_finished_ && tr < endDate) {
            endDate = tr;
        }
        var last_value = null, last_mark = "";
        var rr_list = [];
        var rank = 1;
        r_list.forEach(function (d, i) {
            var res;
            if (r_state[d].next_mark == r_nm) { // passed last mark, so give time o.d
                if (last_value == null) {
                    res = time_to_str(r_state[d].d);
                    last_value = r_state[d].d
                } else {
                    res = "+" + time_to_str(r_state[d].d - last_value, 0);
                }
            } else {
                if (r_state[d].next_mark != last_mark) {  //  min distance is l1 (also try d)
                    last_mark = r_state[d].next_mark;
                    last_value = r_state[d].d
                    res = Math.round(r_state[d].d) + "m";
                    rr_list.push("――― " + r_course[last_mark]
                        .replace(/\/.*/, "").replace(line_list[line_list.length - 1], "finish") + " ―――");
                } else {
                    res = "+" + Math.round(r_state[d].d - last_value) + "m";
                }
            }
            rr_list.push(" ".repeat(3 - r_pos[d].length) + r_pos[d] + " ".repeat(9 - d.length) + d + " " + res);
        });
        r_list = rr_list;
        clist.nrows(r_list.length);
        clist.update(r_list);
        r_current_state = r_state;
    }
    return r_list;
}

var heading_mid = 100;  // global heading_mid, also needed by updatePos
var course_mid = null;

function headingChart(selection) {
    var margin = { top: 0, right: 0, bottom: 20, left: 0 },
        height = 80 - margin.top - margin.bottom,
        width = 120 - margin.left - margin.right,
        heading_mid = null,
        nbin = 36 * 2,
        x;

    var svg = d3.select(selection).append("svg:svg")
        .attr("class", "headingchart")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")")

    var y = d3.scaleLinear()
        .range([height, 0]);

    var xAxis = svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", 8);
    x = d3.scaleLinear()
        .domain([-180 + heading_mid, 180 + heading_mid])
        .range([0, width]);

    xAxis.call(d3.axisBottom(x));

    var yAxis = svg.append("g").style("font-size", 8);
    ;

    function chart() {
    }

    chart.nbin = function (n) {
        nbin = n;
        return chart;
    }
    chart.xmid = function (_) {
        if (_ != heading_mid) {
            heading_mid = _;
            x = d3.scaleLinear()
                .domain([-180 + heading_mid, 180 + heading_mid])
                .range([0, width]);
            xAxis.call(d3.axisBottom(x));
        }
        return chart;
    }
    chart.xmid(0);

    function peakdet(data, delta) {
        //console.log("data is " + data)
        //console.log("delta is " + delta)

        var peaks = [];
        var valleys = [];

        var min = Infinity;
        var max = -Infinity;
        var minPosition = Number.NaN;
        var maxPosition = Number.NaN;

        var lookForMax = true;

        var current;
        // var dbg = [];
        for (var i = 0; i < data.length; i++) {
            current = data[i].length;
            if (current > max) {
                max = current;
                maxPosition = i;
            }
            if (current < min) {
                min = current;
                minPosition = i;
            }
            /*
              dbg.push(
              "looking for max," + lookForMax + ",current," + current + ",pos," +
              i + ",min," + min + ",max," + max + ",delta," + delta + "<br>")
            */

            if (lookForMax) {
                if (current < max - delta) {
                    var avg = data[maxPosition].reduce((a, b) => a + b) / max;
                    peaks.push({ "position": maxPosition, "avg": avg, "value": max });
                    min = current;
                    minPosition = i;
                    lookForMax = false;
                }
            }
            else {
                if (current > min + delta) {
                    valleys.push({ "position": minPosition, "value": min });
                    max = current;
                    maxPosition = i;
                    lookForMax = true;
                }
            }
        }
        return { "peaks": peaks, "valleys": valleys };
    }

    var angle_age = 0;
    chart.update = function (data) {


        var histogram = d3.histogram()
            .value(function (d) { return d; })
            .domain(x.domain())  // then the domain of the graphic
            .thresholds(x.ticks(nbin)); // then the numbers of bins

        // And apply this function to data to get the bins
        var bins = histogram(data);
        window.bins = bins;

        var da2 = Math.max(50, Math.round(bins.flat(2).length * 100 / 1600));
        var res = peakdet(bins, da2).peaks;
        if (res.length > 1) {
            var s = 0, sa = 0, l = [];
            for (var i = 0; i < res.length; i++) {
                var i1 = res[i].position;
                var i0 = (i1 - 1 + nbin) % nbin
                var i2 = (i1 + 1) % nbin

                s = (sum(bins[i0]) + sum(bins[i1]) + sum(bins[i2])) / (
                    bins[i0].length + bins[i1].length + bins[i2].length
                );
                l.push(res[i].position, res[i].avg, [
                    sum(bins[i0]) / bins[i0].length,
                    sum(bins[i1]) / bins[i1].length,
                    sum(bins[i2]) / bins[i2].length],
                    s, i1);
                sa += s;
            }
            if (res.length == 2 && Math.abs(res[0].avg - res[1].avg) > 70) {
                angle_age = 0;
                sa /= res.length;
                histo_wind_angle = sa;
                svg_wind.attr('transform', "translate(-30,-10) rotate(" + (histo_wind_angle + 45 - map_rotation) + " 0 0)");
                d3.select("#wind").select("text").style("opacity", 1).text("" + Math.round((sa + 360) % 360));
            }
        } else {
            angle_age += 1;
            d3.select("#wind").select("text").style("opacity", 1 - Math.min(0.8, angle_age / 20));
        }

        // Y axis: update now that we know the domain
        y.domain([0, d3.max(bins, function (d) { return d.length; })]);   // d3.hist has to be called before the Y axis obviously
        yAxis
            .transition()
            .duration(1000)
            .call(d3.axisLeft(y));

        // Join the rect with the bins data
        var g = svg.selectAll("rect")
            .data(bins)

        g.enter()
            .append("rect") // Add a new rect for each new elements
            .merge(g) // get the already existing elements as well
            .transition() // and apply changes to all of them
            .duration(1000)
            .attr("x", 1)
            .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
            .attr("width", function (d) { return x(d.x1) - x(d.x0); })
            .attr("height", function (d) { return height - y(d.length); })
            .style("fill", "#69b3a2")

        g
            .exit()
            .remove()
        return chart;
    }

    return chart;
}

var cwind = headingChart("#heading").xmid(heading_mid).nbin(36).update([1, 2, 3, 3, 4, 5, 120, 333, 121, 12]);
window.cwind = cwind;


function windSpeedChart(selection, ctype) {

    var margin = { top: 0, right: 0, bottom: 20, left: 30 },
        height = 80 - margin.top - margin.bottom,
        width = 200 - margin.left - margin.right,
        x;

    var svg = d3.select(selection).append("svg:svg")
        .attr("class", "windspeedchart")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")")
    window.svg = svg

    var y = d3.scaleLinear()
        .range([height, 0]);

    var xAxis = svg.append("g").attr("class", "xaxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", 8);

    var yAxis = svg.append("g").attr("class", "yaxis");
    var path = svg.append("g").attr("class", "theline");
    var vertical = svg.append("g").attr("class", "vertical").append("path");
    var chart = {}

    chart.updateVertical = function (t) {
        var d;
        var xpos = x((t - startDate) / 60)
        var d = "M" + xpos + "," + (height);
        d += " " + xpos + "," + margin.top;
        vertical.attr("d", d);
    }

    function updateScales(data) {
        // data is mpos[name] l[0] = dt l[3] = speed l[4] = angle
        var t0 = d3.min(data, function (d) { return (d[0] - startDate) / 60; });
        var t1 = d3.max(data, function (d) { return (d[0] - startDate) / 60; });
        x = d3.scaleLinear()
            .domain([-5, (endDate - startDate) / 60 + 5])
            .range([0, width]);
        y.domain([ctype == 3 ? 0 : d3.min(data, function (d) { return +d[ctype]; }),
        d3.max(data, function (d) { return +d[ctype]; })]);
        xAxis.call(d3.axisBottom(x));
        yAxis.call(d3.axisLeft(y).ticks(3));
    }

    chart.update = function (data) {
        updateScales(data);
        var line = d3.line()
            .x(d => x((d[0] - startDate) / 60))
            .y(d => y(+d[ctype]));
        var path = svg.select("g.theline").selectAll("path").data([data])
        path.enter().append("path")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .merge(path)
            .attr("d", line);
    };
    return chart;

}

/*
var cspeed = windSpeedChart("#windspeed", 3);
var cangle = windSpeedChart("#windangle", 4);
window.cspeed = cspeed;
*/


function rankingList(selection) {
    var row_height = 15,
        n_rows = 1;

    var y = d3.scaleLinear()
        .domain([0, 1])
        .rangeRound([0, row_height]);

    var svg = d3.select(selection).append("svg:svg")
        .attr("class", "chart")
        .attr("width", 140)
        .attr("height", row_height * n_rows);

    function chart() {
    }
    chart();
    window.r_list = r_list;
    chart.update = function (rlist) {
        var cdata = svg.selectAll("text").data(rlist, function (d, i) {
            return d.trim().split(/\s+/)[1]
        });

        cdata.enter().append("text")
            .attr("y", function (d, i) { return y(i + 1); })
            .attr("xml:space", "preserve")
            .text(function (d) { return d; });
        cdata
            .transition()
            .duration(1000 / Math.abs(factor))
            .attr("y", function (d, i) { return y(i + 1); })
            .text(d => d)
        cdata.exit().remove();
        return chart;
    }
    chart.nrows = function (_) {
        if (!arguments.length) return n_rows;
        n_rows = _;
        svg.attr("height", row_height * n_rows);
        return chart;
    }
    return chart;
}

var clist = rankingList("#ranking").nrows(0).update([]);

var label_mode_fn = function (mode) {
    for (let name in track) {
        var s = track[name].getStyle().getText();
        if (mode != 1) {
            s.setRotation(0)
            s.setRotateWithView(false);
            s.setOffsetY(12);
            s.setScale(1);
            s.setFont("");
            s.setScale(0.8 * overall_scale);
            if (mode == 0) s.setText("")
        } else {
            s.setFont("bold");
            s.setText(name.replace(/([A-Z]{3})/, "$1\n"));
            s.setRotateWithView(true);
            s.setOffsetY(0);
            s.setScale(0.8 * overall_scale);
        }
    }
    map.redrawText();
    updatePosX(t);
}
var rescale_timer = null;

var fleet_mode_fn = function (mode) {
    var boat_extent = createEmpty();
    if (boat_mode) {
        if (track.hasOwnProperty(boat_to_follow)) {
            extend(boat_extent, track[boat_to_follow].getGeometry().getExtent());
        }
    }
    if (fleet_mode) {
        for (let name in track) {
            extend(boat_extent, track[name].getGeometry().getExtent());
        }
    }
    if (course_mode) {
        for (let name in marks) {
            if (!marks[name].get("wind")) {
                extend(boat_extent, marks[name].getGeometry().getExtent());
            }
        }
    }
    if (top_mode) {
        if (Object.keys(r_pos).length >= 3) {
            for (var name in track) {
                if (r_pos[name] <= 3 && r_current_state[name].next_mark < r_nm) {
                    extend(boat_extent, track[name].getGeometry().getExtent());
                }
            }
        }
    }

    var w = map.getView().viewportSize_[0];  // should be private ???
    var h = map.getView().viewportSize_[1];  // should be private ???

    var layerExtent = vectorLayer.getSource().getExtent();
    var g = fromExtent(boat_extent)
    var _map_extent = map.getView().calculateExtent();
    var h = fromExtent(_map_extent)
    h.scale(0.7)
    _map_extent = h.getExtent()
    //console.log( getArea(g.getExtent()) / getArea( _map_extent ) )
    if (!isEmpty(boat_extent)) {
        if ((!containsExtent(_map_extent, g.getExtent())
            // || getArea(g.getExtent()) / getArea(_map_extent) < 0.1
            || mode == "force")
            && !map.getView().getAnimating()
        ) {
            g.scale(1 / 0.7 ** 2);
            map.getView().fit(g, {
                size: map.getSize(),
                duration: 1000,
                maxZoom: user_zoom,   // map.getView().getZoom(),
            });
            save_user_zoom = false;
            if (rescale_timer) clearTimeout(rescale_timer);
            rescale_timer = setTimeout(rescale_objects, 2000);
        }
    }
}

var prompt_hist = [];
var prompt_pos = 0;

var clean_command = function (d) {
    d = d.replace(' is ', '=');
    return d;
}

d3.select("#command").on("keydown", function (d) {
    if (d3.event.ctrlKey && d3.event.key == "ArrowUp") {
        if (prompt_pos > 0) {
            prompt_pos -= 1;
            if (prompt_hist.length > prompt_pos) {
                this.value = prompt_hist[prompt_pos];
            }
        }
    } else if (d3.event.ctrlKey && d3.event.key == "ArrowDown") {
        if (prompt_pos < prompt_hist.length) {
            prompt_pos += 1;
            if (prompt_pos == prompt_hist.length) {
                this.value = "";
            } else {
                this.value = prompt_hist[prompt_pos];
            }
        }
    } else if (d3.event.ctrlKey && d3.event.key == "Enter") {
        for (var l of this.value.replace(/\n*$/, "").split("\n")) {
            ws.send(JSON.stringify({
                msg: clean_command(l),
                t: t,
                race: race
            }));
        }
        prompt_hist.push(this.value);
        if (prompt_hist.length > 100) {
            prompt_hist.shift();
        }
        prompt_pos = prompt_hist.length;
        this.value = "";
    }
})

d3.select("#live-delay-toggle").on("click", function (d) {
    live_delay += 5;
    if (live_delay > orig_live_delay) {
        live_delay = 0;
    }
    d3.select("#live-delay-toggle").text(live_delay);
})

// double code, fix this later
d3.select("#send-button").on("click", function (d) {
    var _t = t;
    if (live_mode != null) {
        _t -= live_delay;
    }
    ws.send(JSON.stringify({
        msg: clean_command(document.getElementById("command").value),
        t: _t,
        race: race
    }));
    prompt_hist.push(this.value);
    if (prompt_hist.length > 100) {
        prompt_hist.shift();
    }
    prompt_pos = prompt_hist.length;
    console.log(this);
    document.getElementById('command').value = "";
});

var handle_smap = function (d) {
    if (d3.event.ctrlKey && d3.event.key == "Enter") {
        ws.send(JSON.stringify({
            msg: "smap",
            race: race,
            data: document.getElementById("smap").value
        }));
    }
};

d3.select("#smap").on("keydown", handle_smap);
d3.select("#send-button2").on("click", function (d) {
    ws.send(JSON.stringify({
        msg: "smap",
        race: race,
        data: document.getElementById("smap").value
    }));
});

d3.select("#get-button2").on("click", function (d) {
    ws.send(JSON.stringify({
        msg: "get_smap",
        race: race,
        data: document.getElementById("smap").value
    }));
});

d3.select("#myPass").on("keyup", function () {
    if (d3.event.key == "Enter") {
        ws.send(JSON.stringify({
            race: race,
            t: null,
            msg: "login=" + this.value
        }));
    }
});

d3.select("#help").on("click", function () {
    document.getElementById("help").style.display = "none";
})
d3.select("#help-button").on("click", function () {
    toggle("#help");
})

d3.select("#done_popup").on("click", function () {
    document.getElementById("popup").style.display = "none";
    var password = document.getElementById("pass").value;
    ws.send(JSON.stringify({
        race: race,
        t: null,
        msg: "login=" + password,
    }));
});

d3.select("#cancel_popup").on("click", function () {
    document.getElementById("popup").style.display = "none";

});

function showPopup() {
    document.getElementById("popup").style.display = "block";
}
function toggle(el) {
    var state = d3.select(el).style("display");
    if (state == "none") {
        d3.select(el).style("display", "unset")
    } else {
        d3.select(el).style("display", "none")
    }
}

document.getElementById("myinfo").onclick = function (e) {
    if (admin) {
        toggle("#command");
        toggle("#smap");
        toggle("#send-button");
        toggle("#send-button2");
        toggle("#get-button2");
        toggle("#live-delay-toggle");
        toggle("#ranking");
    } else if (e.detail > 2) showPopup();
}

d3.select("#fleet-button").on("click", () => {
    fleet_mode = !fleet_mode;
    d3.select("#fleet-button").style("opacity", 0.2 + 0.3 * fleet_mode);
    fleet_mode_fn("force");
});

d3.select("#top-button").on("click", () => {
    top_mode = !top_mode;
    d3.select("#top-button").style("opacity", 0.2 + 0.3 * top_mode);
    fleet_mode_fn("force");
});

d3.select("#dist-button").on("click", () => {
    dist_mode = !dist_mode;
    d3.select("#dist-button").style("opacity", 0.2 + 0.3 * dist_mode);
    if (dist_mode) {
        create_distances()
    } else {
        delete_distances()
    }
});

var noSleep = null;
d3.select("#wakelock-button").on("click", () => {
    if (noSleep == null) {
        noSleep = new NoSleep();
        noSleep.enable();
        d3.select("#wakelock-button").style("opacity", 0.2 + 0.3);
    } else {
        noSleep.disable();
        d3.select("#wakelock-button").style("opacity", 0.2);
        noSleep = null;
    }
});

d3.select("#boat-button").on("click", () => {
    boat_mode = !boat_mode;

    if (boat_mode && boat_to_follow == "") d3.select("#boat-button").text("click boat");
    if (!boat_mode && boat_to_follow == "") d3.select("#boat-button").text("boat");

    d3.select("#boat-button").style("opacity", 0.2 + 0.3 * boat_mode);
    fleet_mode_fn("force");
});

d3.select("#label-button").on("click", () => {
    label_mode = (label_mode + 1) % 7;
    d3.select("#label-button").text({ 0: "clear", 1: "sailno", 2: "info", 3: "knots", 4: "rssi", 5: 'sfreq', 6: 'pos' }[label_mode]);
    label_mode_fn(label_mode);
});

d3.select("#trail-button").on("click", () => {
    var trail = document.getElementById("trail-button").innerText;
    trail = { '1': '2', '2': '5', '5': '10', '10': '20', '20': '30', '30': 'inf', 'inf': '1' }[trail];
    document.getElementById("trail-button").innerText = trail;
    if (trail == "inf") {
        trail_length = 10000;
    } else {
        trail_length = +trail;
    }
    updatePosX(t);
});

d3.select("#course-button").on("click", () => {
    course_mode = !course_mode;
    d3.select("#course-button").style("opacity", 0.2 + 0.3 * course_mode);
    fleet_mode_fn("force");
});

d3.selectAll(".ol-zoom-in,.ol-zoom-out,.ol-full-screen-false,.ol-rotate-reset")
    .style("background-color", 'black')
    .style("margin", 0)
    .style("border-radius", 0)
    .style("border", 0)
    .style("opacity", 0.5);
d3.selectAll(".ol-attribution").style("font-size", "12px");


/*map.addEventListener('keydown', (e) => {
    console.log(e);
    if (e.originalEvent.key == " ") {
        toggle_play();
    }
})
*/

var wind_speed, wind_angle;

var svg_wind = d3.select("#wind")
    .append("svg")
    .attr("width", 30)
    .attr("height", 30);

svg_wind.append("svg:defs").append("svg:marker")
    .attr("id", "triangle")
    .attr("refX", 0)
    .attr("refY", 2)
    .attr("markerWidth", 10)
    .attr("markerHeight", 10)
    .attr("orient", "auto")
    .append("path")
    .attr("d", "M0,0 L0,4 L5,2 z")
    .style("fill", "black")
    .attr("stroke-width", 0)

svg_wind.append("line")
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", 10)
    .attr("y2", 10)
    .attr("stroke-width", 3)
    .attr("stroke", "black")
    .attr("opacity", 0.3)
    .attr("marker-end", "url(#triangle)");
